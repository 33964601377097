import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import { ContentMatrix } from '../../components/content-matrix';
import { floodlightPageLoadEventPropTypes } from '../../prop-types';
import { Layout } from '../../components/layout';
import { makeInitialProps } from '../../utils/make-initial-props';
import { seoPropDefaults, seoPropTypes } from '../../utils/extract-seo-meta-from-entry';
import Error from '../_error';

const Page = ({ contentMatrix, metaContent, statusCode, title, parent, errorMessage, floodlightPageLoadEvent }) => {
  if (errorMessage) {
    return <Error statusCode={statusCode} errorMessage={errorMessage} />;
  }

  if (isEmpty(contentMatrix)) {
    return <Error statusCode={404} errorMessage={errorMessage} />;
  }

  const breadcrumbs = {
    title,
    ancestors: parent
      ? [
          {
            title: parent.title,
            url: `/${parent.slug}`,
          },
        ]
      : null,
  };

  return (
    <Layout title={title} metaContent={metaContent} floodlightPageLoadEvent={floodlightPageLoadEvent}>
      <ContentMatrix blocks={contentMatrix} breadcrumbs={breadcrumbs} />
    </Layout>
  );
};

Page.getInitialProps = makeInitialProps();

Page.propTypes = {
  contentMatrix: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorMessage: PropTypes.string,
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  metaContent: PropTypes.shape(seoPropTypes),
  parent: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  statusCode: PropTypes.number,
  title: PropTypes.string.isRequired,
};

Page.defaultProps = {
  errorMessage: undefined,
  floodlightPageLoadEvent: undefined,
  metaContent: seoPropDefaults,
  parent: undefined,
  statusCode: 200,
};

export default Page;
